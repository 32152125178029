<template>
  <div class="account-overview" v-loading="loading">
    <div class="person-header flex-sb">
      <p>
        <strong><span v-if="currentIdentity==1">[个人]</span><span v-else-if="currentIdentity==2">[企业]</span>账户总览</strong>

      </p>
      <!--      <p class="person-b">-->
      <!--        <b class="b" @click="toMdetail">资金明细</b>-->
      <!--      </p>-->
    </div>
    <div class="withdraw-views-bottom">
      <div class="withdraw-views-bottom1">
        <span>累计支出</span>
        <p><strong>{{ wallet.acc_outlay_yuan }}</strong><span>元</span></p>
        <el-tooltip class="item" effect="dark" content="包含已托管的项目资金或待支付的项目佣金" placement="bottom">
        <button>待支出<strong style="padding:0 4px;font-size:18px;">{{ wallet.freeze_all_out_yuan }}</strong><span>元</span></button>
        </el-tooltip>
      </div>
      <div class="withdraw-views-bottom2">
        <span>可用余额</span>
        <p><strong>{{ wallet.available_all_yuan }}</strong><span>元</span></p>
        <button class="h-57">八爪账户<strong style="padding:0 4px;font-size:18px;">{{ wallet.available_plt_yuan }}</strong><span>元</span></button>
        <button class="h-57">第三方存管<strong style="padding:0 4px;font-size:18px;">{{ wallet.available_bank_yuan }}</strong><span>元</span></button>
      </div>
      <div class="withdraw-views-bottom3">
        <span>累计收益</span>
        <p><strong>{{ wallet.acc_income_yuan }}</strong><span>元</span></p>
        <button class="h-57">未入账<strong style="padding:0 4px;font-size:18px;">{{ wallet.freeze_in_yuan }}</strong><span>元</span></button>
        <el-tooltip class="item" effect="dark" content="提现功能暂未开放" placement="bottom">
          <button class="bluebutton">申请提现</button>
        </el-tooltip>
      </div>
    </div>
    <div class="mb-60"></div>
    <div class="mb-10"></div>
    <div class="account-analyse">
      <div class="account-analyse-header">
        <strong>资金明细预览</strong>
      </div>
      <div class="account-analyse-date">
        <!--        <div class="mb-20"></div>-->
        <!--        <ul>-->
        <!--          <li><span>时间</span></li>-->
        <!--          <li><span>7天</span></li>-->
        <!--          <li><span>30天</span></li>-->
        <!--          <li>-->
        <!--            <el-date-picker v-model="value1" type="daterange"-->
        <!--                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">-->
        <!--            </el-date-picker>-->
        <!--          </li>-->
        <!--        </ul>-->
        <div class="account-analyse-date-border">
          <el-table :data="detailTableData" style="width: 100%"
                    center class="checkmantab" border key="two"
                    :header-cell-style="{ 'text-align': 'center',height: '32px', color: '#000','font-size':'12px',background:'#f4f4f4'}"
                    :cell-style="{ 'text-align': 'center', height: '32px', color: '#474747','font-size':'12px'}"> >
            <el-table-column label="序号" key="twoC1" type="index"></el-table-column>
            <el-table-column  label="收入/支出" width="80">
              <template slot-scope="{row}">
                {{ row.type == 'i' ? '收入' : '支出' }}
              </template>
            </el-table-column>
            <el-table-column prop="bill_type_name" label="资金类型"></el-table-column>
            <el-table-column prop="fund_type_name" label="账户"></el-table-column>
            <el-table-column label="金额">
              <template slot-scope="{row}">
                {{ row.type == 'i' ? '+' : '-'}}{{row.amount_yuan}}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="账单时间" width="160"></el-table-column>
            <el-table-column prop="left_available_all_yuan" label="可用余额"></el-table-column>
            <el-table-column prop="memo" label="备注"></el-table-column>
          </el-table>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="detailPagination.total"
              :page-size="detailPagination.page_size"  />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWallet, getWalletDetails} from "@/api/user.js"

export default {
  data() {
    return {
      loading: false,
      wallet: {},
      detailSearchParams: {
        page: 1,
        kw: ''
      },
      detailTableData: [],
      detailPagination: {},
      currentUser: {},
      identity: 1
    }
  },
  computed : {
    currentIdentity() {
      return this.$store.state.identity
    }
  },
  watch: {
    currentIdentity( newval, oldval ) {
      if( oldval!=null ){
        this.loadData()
        this.loadWalletDetails()
      }
    }
  },
  created() {
    this.currentUser = this.$store.getters["useUserStore/getUserInfo"]
    if(this.$store.state.identity==0) {
      this.$store.state.identity = localStorage.getItem('identity_' + this.currentUser.id);
    }
    this.loadData()
    this.loadWalletDetails()
  },
  methods: {
    async loadData() {
      this.loading = true
      let res = await getWallet({wallet_type: this.currentIdentity});
      if (res.statusCode == 200) {
        this.wallet = res.data
      }
      this.loading = false
    },
    loadWalletDetails() {
      this.detailSearchParams.wallet_type = this.currentIdentity
      getWalletDetails(this.detailSearchParams).then(res => {
        if (res.statusCode == 200) {
          this.detailTableData = res.data.list;
          this.detailPagination = res.data.pages;
        }
      });
    },
    handleSizeChange(){

    },
    handleCurrentChange(val){
      this.detailSearchParams.page = val
      this.loadWalletDetails(this.detailSearchParams)
    }
  }
}
</script>

<style lang="less" scoped>
.account-overview {
  width: 940px;
  min-height: 930px;
  background-color: #fff;

  .person-header {
    .person-b {
      margin-right: 40px;
    }
  }

  .withdraw-views-bottom {
    display: flex;
    margin-top: 40px;
    align-items: center;

    .withdraw-views-bottom1,
    .withdraw-views-bottom2,
    .withdraw-views-bottom3 {
      text-align: center;
      flex:1;
      display: inline-block;

      span {
        font-size: 14px;
        color: #575757;
      }

      p {
        margin: 25px 0;

        strong {
          font-size: 36px;
        }
      }

      button {
        font-size: 14px;
        height: 24px;
        background-color: #f8f8f8;
        border-radius: 12px;
        color: #575757;
        padding: 0px 15px;
      }
    }

    .withdraw-views-bottom1 {
    }

    .withdraw-views-bottom2 {
      button {
        cursor: pointer;
      }
    }

    .withdraw-views-bottom3 {
      .bluebutton {
        color: #fff;
        background-color: #00a2e6;
        cursor: pointer;
      }
    }
  }

  .account-analyse {
    .account-analyse-header {
      height: 36px;
      border-bottom: 1px solid #b5b5b5;

      strong {
        position: relative;
        left: 40px;
      }
    }

    .account-analyse-date {
      padding: 0px 15px 30px;

      .account-analyse-date-border {
        padding-top: 20px;
      }
    }
  }
}
</style>
